import styled, { css } from "styled-components";
import { Colors, Rem } from "../../commons/Theme";

export const StyledDescriptionBlock = styled.div`
  color: ${Colors.white};
  background-color: ${Colors.black};
  padding: ${Rem(20)};
  
  ${(props) =>
    props.isWhite
    && css`
      color: ${Colors.black};
      background-color: ${Colors.white};
    `}
`;

export const StyledDescriptionBlockTitle = styled.p`
  border-bottom: 1px solid ${Colors.yellow};
  display: inline-block;
  padding-bottom: ${Rem(9)};
  font-size: ${Rem(30)};
  margin-bottom: ${Rem(30)};
`;

export const StyledDescriptionBlockBody = styled.p`
  line-height: ${Rem(22)};
  margin-bottom: ${Rem(30)};
`;

export const StyledDescriptionBlockButton = styled.div`
  display: inline-block;
  font-size: ${Rem(20)};
  background-color: ${Colors.yellow};
  padding: ${Rem(10)};
  cursor: pointer;
`;
